import React from 'react';
import './Language.scss';

export const LanguageContext = React.createContext({})

const data = {
    icon: {
        pt: require('../assets/brazil-flag.png'),
        en: require('../assets/united-kingdom.png')
    }
}

export const LanguageProvider = class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'pt'
        }
    }
    componentDidMount() {
        if (typeof window !== 'undefined') {
            this.setState(() => ({ ...this.state, language: window.localStorage.getItem('language') ? window.localStorage.getItem('language') : 'pt' }))
        }
    }
    agree = () => {
        if (typeof window !== 'undefined') {
            return window.localStorage.getItem('agree')
        }
    }

    render() {
        const setLanguage = (language) => { if (this.agree()) { window.localStorage.setItem('language', language) }; if (language !== this.state.language) { this.setState({ ...this.state, language }) } }
        return (
            <LanguageContext.Provider value={{ ...this.state, setLanguage }}>
                {this.props.children}
            </LanguageContext.Provider>
        );
    }
}

export const LanguageSwitcher = () => {
    return <LanguageContext.Consumer>{({ language, setLanguage }) => (<div className='flags'><div className={(language === 'pt' ? 'selected' : '') + ' flag'}><img alt='icon_ptbr' width='15px' src={data.icon.pt} onClick={() => { setLanguage('pt') }} /></div><div className={(language === 'en' ? 'selected' : '') + ' flag'}><img alt='icon_en' width='15px' src={data.icon.en} onClick={() => { setLanguage('en') }} /></div></div>)}</LanguageContext.Consumer>
}