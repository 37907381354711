import React, { Component } from 'react'
import { LanguageContext } from '../../contexts/Language'
import './CookieBanner.scss'

const data = {
  cookie: {
    desc: {
      en: <span>This website uses cookies to provide features and offer you a customized experience. By using this website you agree with cookies as stated in <a className="politics-link" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/e/2PACX-1vTh4ZMGfrFEyTE0GplKFQJLojqsCsdZAqHTQHFe2W8Ck9aWAXrnutY-h0-xiErBR2aNQbRmMSUTYZqa/pub">our privacy agreement.</a></span>,
      pt: <span>Este website utiliza cookies para disponibilizar algumas funcionalidades e melhorar sua experiência online. Utilizando este website, você concorda com a utilização de cookies conforme especificado nos <a className="politics-link" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/e/2PACX-1vTh4ZMGfrFEyTE0GplKFQJLojqsCsdZAqHTQHFe2W8Ck9aWAXrnutY-h0-xiErBR2aNQbRmMSUTYZqa/pub">termos de política de privacidade.</a></span>
    }
  },
  agree: {
    title: {
      pt: 'Concordo',
      en: 'Agree'
    }
  }
}

export default class CookieBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      agree: undefined
    }
  }
  showBanner = () => {
    if (typeof window !== 'undefined') {
      return window.localStorage.getItem('agree')
    }
    if (this.state) {
      return !this.state.agree
    }
  }
  agree = () => {
    this.setState(() => ({ ...this.state, agree: true }))
    window.localStorage.setItem('agree', true)
  }
  render() {
    return (
      typeof window !== 'undefined' && this.showBanner() ? <></> :
        <LanguageContext.Consumer>
          {({ language }) => (
            < div className='cookie-banner' >
              {data.cookie.desc[language]}
              <div className='agree-and-close' onClick={this.agree}>
                <a className='agree-button'>{data.agree.title[language]}</a>
              </div>
            </div >
          )
          }
        </LanguageContext.Consumer>
    )
  }
}
