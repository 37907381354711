import React, { Component } from 'react'

import MenuItem from './MenuItem'
import MenuButton from './MenuButton'
import Menu from './Menu'

import './main.scss'
import { LanguageContext, LanguageSwitcher } from '../../contexts/Language'

const data = {
  about: {
    title: { pt: 'SOBRE NÓS', en: 'ABOUT' }
  },
  services: {
    title: { pt: 'SERVIÇOS', en: 'SERVICES' }
  },
  customers: {
    title: { pt: 'CLIENTES', en: 'CUSTOMERS' }
  },
  contact: {
    title: { pt: 'CONTATO', en: 'CONTACT' }
  }
}

class Main extends Component {
  state = {
    menuOpen: false,
  }

  onMenuClick = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    })
  }

  onLinkClick = () => {
    this.setState({
      menuOpen: false,
    })
  }

  render() {
    return (
      <LanguageContext.Consumer>
        {({ language }) => {
          const menu = [
            {
              val: data.about.title[language],
              path: './',
            },
            {
              val: data.services.title[language],
              path: './services',
            },
            {
              val: data.customers.title[language],
              path: './#anchor_section4',
            },
            {
              val: data.contact.title[language],
              path: './services/#contact_anchor',
            },
            {
              val: 'BLOG',
              path: 'https://medium.com/barlavento',
            },
          ]

          const menuItems = menu.map((val, index) => {
            return (
              <MenuItem
                key={index}
                delay={`${index * 0.1}s`}
                onClick={() => {
                  this.onLinkClick()
                }}
                path={val.path}
              >
                {val.val}
              </MenuItem>
            )
          })
          return (
            <div>
              <div className="header_container">
                <MenuButton
                  open={this.state.menuOpen}
                  onClick={() => this.onMenuClick()}
                />
              </div>

              <Menu open={this.state.menuOpen}>
                {menuItems}
                <div style={{ margin: '0 5%', height: '20px' }}>
                  <LanguageSwitcher />
                </div>
              </Menu>
            </div >
          )
        }
        }
      </LanguageContext.Consumer >)
  }
}

export default Main
