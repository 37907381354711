import React, { Component } from 'react'

import './menu.scss'


class Menu extends Component 
{

    constructor(props)
    {
        super(props)
        
        this.state=
        {
            open: this.props.open? this.props.open:false,
        }
    }
    
    // eslint-disable-next-line
    componentWillReceiveProps(nextProps)
    {
        if(nextProps.open !== this.state.open)
        {
            this.setState(
                {
                    open:nextProps.open
                }
            )
        }
    }


    render()
    {
        return(
            <div className='menu_container' style={{height: this.state.open? '80vw': 0}}>
                {
                    this.state.open
                    ?
                        <div style={{paddingTop: '16vw'}}> {/* 60px */}
                            {this.props.children}
                        </div>
                    :
                    null
                }
            </div>
        )
    }
}

export default Menu
