import React from 'react'
import { Link } from 'gatsby'

import './menuItem.scss'

export default ({ children, path, onClick, delay }) => {
  const styles = {
    opacity: 0,
    animation: '1s appear forwards',
    animationDelay: delay,
  }

  return (
    <div style={styles}>
      {children === 'BLOG' ? (
        <a href={path} className="menuItem" onClick={onClick}>
          {children}
        </a>
      ) : (
        <Link to={path} className="menuItem" onClick={onClick}>
          {children}
        </Link>
      )}
    </div>
  )
}
