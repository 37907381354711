import React, { Component } from 'react'

import './menuButton.scss'



class MenuButton extends Component 
{
    constructor(props)
    {
        super(props)

        this.state=
        {
            open: this.props.open? this.props.open:false,
        }
    }

    // eslint-disable-next-line
    componentWillReceiveProps(nextProps)
    {
        if(nextProps.open !== this.state.open)
        {
            this.setState(
                {
                    open:nextProps.open
                }
            )
        }
    }

    handleClick = () =>
    {
        this.setState(
            {
                open:!this.state.open
            }
        )
    }


    render()
    {
        const styles = 
        {
            line: 
            {
                height: '.53vw', // 2px
                width: '5.33vw', // 20px
                background: '#d8d8d8',
                transition: 'all 0.2s ease',
            },
            lineTop: 
            {
                transform: this.state.open ? 'rotate(45deg)':'none',
                transformOrigin: 'top left',
                marginBottom: '1.33vw', // 5px
            },
            lineMiddle: 
            {
                opacity: this.state.open ? 0: 1,
                transform: this.state.open ? 'translateX(-4.26vw)':'none', // -16px
            },
            lineBottom: 
            {
                transform: this.state.open ? 'translateX(-1px) rotate(-45deg)':'none',
                transformOrigin: 'top left',
                marginTop: '1.33vw', // 5px
            },       
        }

        return(
            <div 
                className='menuButton_container'
                onClick=
                {
                    this.props.onClick ? this.props.onClick : ()=> {this.handleClick()}
                }
            >
                <div style={{...styles.line,...styles.lineTop}}/>
                <div style={{...styles.line,...styles.lineMiddle}}/>
                <div style={{...styles.line,...styles.lineBottom}}/>
            </div>
        )
    }
}

export default MenuButton
