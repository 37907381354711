import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Link } from 'gatsby'
import './Nav.scss'
import { LanguageContext, LanguageSwitcher } from '../../contexts/Language'

const data = {
  about: {
    title: { pt: 'SOBRE NÓS', en: 'ABOUT' }
  },
  services: {
    title: { pt: 'SERVIÇOS', en: 'SERVICES' }
  },
  customers: {
    title: { pt: 'CLIENTES', en: 'CUSTOMERS' }
  },
  contact: {
    title: { pt: 'CONTATO', en: 'CONTACT' }
  }
}

export default props => (
  <LanguageContext.Consumer>
    {({ language }) => {
      return (<nav id="nav" className={props.sticky ? 'navTwo' : 'navOne'}>
        <img
          src={require('../../assets/logo_small.svg')}
          className="logo_small"
          alt="logo_small"
        />
        <div className="navBox">
          <Link to="/" className="menu">
            {data.about.title[language]}
          </Link>
          <Link to="/services" className="menu">
            {data.services.title[language]}
          </Link>
          <AnchorLink to="/#anchor_section4" title={data.customers.title[language]} className="menu" />
          <AnchorLink
            to="/services#contact_anchor"
            title={data.contact.title[language]}
            className="menu"
          />
          <a href="https://medium.com/barlavento" className="menu">
            BLOG
          </a>
          <a className="menu"><LanguageSwitcher /></a>
        </div>
        <div className="barra" />
      </nav>)
    }}
  </ LanguageContext.Consumer>
)