import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import { StaticQuery, graphql } from 'gatsby'

import { Nav } from './nav'
import { NavSmall } from './nav_small'
import { LanguageProvider } from '../contexts/Language'
import { CookieBanner } from './cookie_banner'


class Layout extends Component {
    state =
        {
            stickyNav: true
        }

    _handleWaypointEnter = () => {
        this.setState(() => ({ stickyNav: false }))
    }

    _handleWaypointLeave = () => {
        this.setState(() => ({ stickyNav: true }))
    }

    render() {
        const { children } = this.props

        return (
            <StaticQuery
                query={graphql`
                query SiteTitleQuery {
                    site {
                    siteMetadata {
                        title
                    }
                    }
                }
                `}

                render={data =>
                (
                    <LanguageProvider>
                        <CookieBanner />
                        <Helmet
                            title={data.site.siteMetadata.title}
                            meta=
                            {
                                [
                                    { name: 'description', content: 'Sample' },
                                    { name: 'keywords', content: 'sample, something' },
                                ]
                            }
                        >

                            <html lang="en" />

                            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=yes" />
                            <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,700" rel="stylesheet" />

                        </Helmet>

                        <div className='page_wrap'>

                            <Nav sticky={this.state.stickyNav} />
                            <NavSmall />

                            <Waypoint
                                onEnter={this._handleWaypointEnter}
                                onLeave={this._handleWaypointLeave}
                            />

                            {children}

                        </div>
                    </ LanguageProvider>
                )
                }
            />
        )
    }
}

Layout.propTypes =
{
    children: PropTypes.node.isRequired,
}

export default Layout
